














































































import useSelectItems from "@/use/selectItems";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },

  setup(_, { root }) {
    const { registrationTypeItems } = useSelectItems({ root });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      loaded: false,
      defaultTemplate: false,
    });

    const model = reactive<{
      editor: any;
      data: any;
    }>({
      data: {
        name: "",
        type: "",
        title: "Rejestracja na {{ event.name }}",
        defaultTemplate: "",
      },
      editor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
    });

    //  Start fetch single message data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/${root.$route.params.id}`)
        .then(({ data: { messageTemplate } }) => {
          model.data.name = messageTemplate.name || undefined;
          model.data.type = messageTemplate.type;
          model.data.title = messageTemplate.title || undefined;
          model.data.defaultTemplate = messageTemplate.default || false;
          state.defaultTemplate = messageTemplate.default || false;
          model.editor.design = messageTemplate.design || undefined;
          state.loaded = true;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single message data

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        type: model.data.type || undefined,
        title: model.data.title || null,
        defaultTemplate: model.data.defaultTemplate || false,
        design: model.editor.design || undefined,
        template: model.editor.html || undefined,
      };

      state.loading = true;

      axiosInstance
        .put(`message-template/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.editor = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Szablon już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      registrationTypeItems,
    };
  },
});
